<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="门诊排班"/>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-form-item label="院区" prop="hospital_id">
                <radio-hospital v-model="form.hospital_id" />
            </el-form-item>
            <el-form-item label="预约类型" prop="type_id">
                <radio-type v-model="form.type_id" :hospital_id="form.hospital_id" />
            </el-form-item>
            <el-form-item label="科室" prop="department_id">
                <radio-department v-model="form.department_id" :hospital_id="form.hospital_id" :type_id="form.type_id" />
            </el-form-item>
            <el-form-item label="医生" prop="doctor_id">
                <radio-doctor v-model="form.doctor_id" :hospital_id="form.hospital_id" :type_id="form.type_id" :department_id="form.department_id" />
            </el-form-item>
            <el-form-item label="出诊周期" prop="weekday">
                <checkbox-weekday v-model="form.weekday"/>
            </el-form-item>
            <el-form-item label="分时设置" prop="times">
                <time-inputs v-model="form.times" />
            </el-form-item>
            <el-form-item label="备注" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm">保 存</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import CheckboxWeekday from "@/components/form/checkboxWeekday";
    export default {
        components: {CheckboxWeekday},
        data() {
            return {
                form: {
                    hospital_id: '',
                    department_id: '',
                    doctor_id: '',
                    weekday: [],
                    times: [],
                    desc: ''
                },
                rules: {
                    hospital_id: [
                        {required: true, message: '请选择院区', trigger: 'change'}
                    ],
                    type_id: [
                        {required: true, message: '请选择类型', trigger: 'change'}
                    ],
                    department_id: [
                        {required: true, message: '请选择科室', trigger: 'change'}
                    ],
                    doctor_id: [
                        {required: true, message: '请选择医生', trigger: 'change'}
                    ],
                    weekday: [
                        {required: true, message: '请选择出诊周期', trigger: 'blur'},
                    ],
                    times: [
                        {required: true, message: '请输入分时设置', trigger: 'blur'},
                    ],
                }
            };
        },
        methods: {
            async submitForm() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/worksheet/${this.form.id}` : `/mingde/worksheet/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    await this.$router.replace("/main/worksheet")
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/mingde/worksheet/${id}`)
                    this.form = resp.data.data
                    let arr = resp.data.data.times
                    arr.sort((a,b) => {
                        return a.time > b.time ? 1 : -1;
                    })
                    this.form.times = resp.data.data.times
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
